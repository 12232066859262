import { useApi } from '~/composables/shared/useApi'
import { useSafe } from '~/composables/shared/useSafe';
import type { Result } from "@frontend/ui-kit/src/types/result";
import moment from 'moment';
import { ROUTES } from '~/constants/routes';

const { tryAction } = useSafe();
const { getSecurityAbsoluteUrl, get, post, put, patch } = useApi();

/** Методы работы с данными пользователя. */
export const useUser = () => {
    /**
     * Возвращает данные пользователя.
     * @param userId Идентификатор пользователя.
     * @returns Данные пользователя.
     */
    const getUser = async (userId: string) : Promise<Result<IUserData>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/user/' + userId);
            const result = await get<IUserApiData>(url);
            return mapUser(result);
        });
    };
  
    /**
     * Возвращает постраничный список пользователей.
     * @param filter Фильтр.
     * @returns Список пользователей.
     */
    const getUsersPagedList = async(filter: IUserPagedListFilter) : Promise<Result<IUserPagedList>> => {
        return await tryAction(async () => {
            let url = `${getSecurityAbsoluteUrl(ROUTES.users)}?take=${filter.take}&skip=${filter.skip}`;
            filter.search = filter.search || '';
            if(filter.search?.length > 0) {
                url = `${url}&query=${filter.search}`
            }
            return await get<IUserPagedList>(url);
        });
    }

    /**
     * Изменяет данные пользователя.
     * @param userId Идентификатор пользователя.
     * @param userData Новые данные пользователя.
     */
    const updateUser = async (userId: string, userData: IUserData) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/user/' + userId);
            return await put(url, userData);
        });
    };
    
    /**
     * Изменяет данные пользователя.
     * @param userId Идентификатор пользователя.
     * @param field Изменяемое поле.
     * @param userData Новые данные пользователя.
     */
    const updateUserField = async (userId: string, field: string, value: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/${field}`);
            return await patch(url, `"${value}"`);
        });
    };

    /**
     * Изменяет номер телефона.
     * @param userId Идентификатор пользователя.
     * @param phoneNumber Номер телефона.
     */
    const updatePhoneNumber = async(userId: string, phoneNumber: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/phone/${phoneNumber.replace(/\D/g, '')}`);
            return await put(url, {});
        });
    }

    /**
     * Отправляет верификационный код на номер телефона.
     * @param userId Идентификатор пользователя.
     * @param phoneNumber Номер телефона.
     */
    const sendPhoneVerificationCode = async (userId: string, phoneNumber: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/verification-code/send?phoneNumber=${phoneNumber}`);
            return await post(url, {});
        });
    }

    /**
     * Изменяет роль пользователя.
     * @param userId Идентификатор пользователя.
     * @param role Роль.
     */
    const changeUserRole = async (userId: string, role: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/role/${role}`);
            return await put(url, {});
        });
    }

    /**
     * Блокирует пользователя.
     * @param userId Идентификатор пользователя.
     */
    const blockUser = async(userId: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/status`);
            return await put(url, { isEnabled : false });
        });
    }

    /**
     * Блокирует пользователя.
     * @param userId Идентификатор пользователя.
     */
    const unblockUser = async(userId: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/status`);
            return await put(url, { isEnabled : true });
        });
    }
    
    /**
     * Отправляет верификационный код на email.
     * @param userId Идентификатор пользователя.
     * @param email Email.
     */
    const sendEmailVerificationCode = async(userId: string, email: string) : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl(`/user/${userId}/email/verify?email=${email}`);
            return await post(url, {});
        });
    }

    const mapUser = (apiEntity: IUserApiData) : IUserData => {
        return {
            lastName: apiEntity.lastName,
            firstName: apiEntity.firstName,
            sex: apiEntity.sex,
            email: apiEntity.email,
            isEmailVerified: apiEntity.isEmailVerified,
            isBirthdateChanged: apiEntity.isBirthdateChanged,
            phoneNumber: apiEntity.phoneNumber,
            role: apiEntity.role || undefined,
            birthDate: apiEntity.birthDate ? moment.utc(apiEntity.birthDate).toDate() : undefined,
            createDate: apiEntity.createDate ? moment.utc(apiEntity.createDate).toDate() : undefined
        }
    }

    return {
        getUser,
        updateUser,
        updateUserField,
        updatePhoneNumber,
        sendPhoneVerificationCode,
        changeUserRole,
        getUsersPagedList,
        blockUser,
        unblockUser,
        sendEmailVerificationCode
    };
};