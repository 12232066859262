<script lang="ts" setup>
import { useTheme } from 'vuetify';
const theme = useTheme();
</script>

<template>
    <v-theme-provider :theme="theme.global.name.value">
        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
    </v-theme-provider>
</template>