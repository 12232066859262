import { useAuthStore } from '~/stores/auth/useAuthStore';
import { useApi } from '~/composables/shared/useApi'
import { useSafe } from '~/composables/shared/useSafe';
import type { Result } from "@frontend/ui-kit/src/types/result";

const { tryAction, trySilentAction } = useSafe();
const { getSecurityAbsoluteUrl, post } = useApi();

/** Методы авторизации. */
export const useAuthorization = () => {

    /**
     * Отправляет СМС код.
     * @param phone номер телефона.
     */
    const sendSmsCode = async (phone: string) : Promise<Result<void>> => {
        return tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/code/send?destination=' + phone);
            return post(url, { });
        });
    }

    /**
     * Авторизация
     * @param data Данные авторизации. 
     * @returns Токен.
     */
    const authorize = async (data: ILoginData) : Promise<Result<string>> => {
        return tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/login');
            return post<string>(url, data);
        });
    }

    /** Разлогин. */
    const signOut = async () : Promise<Result<void>> => {
        return await tryAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/logout');
            await post(url, { });

            resetAuth();
        });
    }

    /** Обновление токена */
    const refreshToken = async () => {
        const result = await trySilentAction(async () => {
            const url = getSecurityAbsoluteUrl('/identify/token/refresh');
            await $fetch(url, {
                server: false,
                credentials: 'include'
            });
        });
        return !result.isError;
    }

    const resetAuth = () => {
        const store = useAuthStore();
        store.logout();

        return redirectToSiteLoginUrl();
    }

    /** Переброс на страницу для авторизации сайта. */
    const redirectToSiteLoginUrl = () => {
        const config = useRuntimeConfig();
        return navigateTo(config.public.siteLoginUrl, { external: true });
    }

    /** Переброс на страницу для авторизации админа в ЛК. */
    const redirectToAdminLoginUrl = () => {
        const config = useRuntimeConfig();
        const router = useRouter();
        const encodedReturnUrl = getRelativeCurrentUrl();
        return router.push({ path: config.public.adminLoginUrl, query: { return_url: encodedReturnUrl } });
    }

    /** Переброс на страницу для авторизации клиента в ЛК. */
    const redirectToClientLoginUrl = () => {
        const config = useRuntimeConfig();
        const router = useRouter();
        const encodedReturnUrl = getRelativeCurrentUrl();
        return router.push({ path: config.public.clientLoginUrl, query: { return_url: encodedReturnUrl } });
    }

    const getRelativeCurrentUrl = () : string => {
        // Получаем текущий URL
        const currentUrl = window.location.href;
        // Создаем объект URL
        const url = new URL(currentUrl);
        // Получаем относительный путь
        const relativePath = url.pathname + url.search; // добавляем search, если нужно

        return relativePath;
    }

    return {
        sendSmsCode,
        authorize,
        signOut,
        refreshToken,
        redirectToAdminLoginUrl,
        redirectToClientLoginUrl
    }
}