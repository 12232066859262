import { useAuthStore } from '~/stores/auth/useAuthStore';

/** Middleware проверяет авторизацию. */
export default defineNuxtRouteMiddleware(async () => {
    // токен на стороне клиента, поэтому серверную обработку пропускаем
    if(import.meta.server) {
        return;
    }

    const store = useAuthStore();
    await store.init();
})